/*###########################################################################
// App
//#########################################################################*/

import * as rive from "@rive-app/canvas";

files.forEach(file => {
    const canvasElement = document.getElementById(file.id);

    if (canvasElement) {
        const r = new rive.Rive({
            src: file.url, // Use the provided URL from the Twig
            canvas: canvasElement,
            autoplay: true,
            clearCanvas: false,
            background: "transparent",
            stateMachines: ["State Machine 1"], // Update if needed
            onLoad: () => {
                r.resizeDrawingSurfaceToCanvas();
                const inputs = r.stateMachineInputs("State Machine 1");
                // Custom logic for handling inputs can be placed here
            },
        });
    } else {
        console.error(`Canvas element with ID "${file.id}" not found.`);
    }
});

// const canvasElement = document.getElementById("canvas");

// const r = new rive.Rive({
//   src: "/animations/FILE NAME HERE.riv",
//   canvas: canvasElement,
//   autoplay: true,
//   stateMachines: ["State Machine 1"], // Replace with your state machine name
//   onLoad: () => {
//     r.resizeDrawingSurfaceToCanvas();

//     // Get the inputs from the state machine
//     const inputs = r.stateMachineInputs("State Machine 1");
//     const hoverInput = inputs.find(input => input.name === "Hover");

//     // Ensure the hover input exists
//     if (hoverInput) {
//       // Add event listeners for hover events
//       canvasElement.addEventListener("mouseenter", () => {
//         hoverInput.value = true;
//       });

//       canvasElement.addEventListener("mouseleave", () => {
//         hoverInput.value = false;
//       });
//     } else {
//       console.error("Hover input not found in the state machine.");
//     }
//   },
// });


/* CSS
//------------------*/

import "@css/app.css";

/* App
//------------------*/

window.app = {
	disableScroll() {
		document.documentElement.style.overflow = 'hidden'
	},

	enableScroll() {
		document.documentElement.style.overflow = ''
	}
}

/* Vendor
//------------------*/

import '@js/vendor/index'

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
*/
import Alpine from 'alpinejs';
import contactForm from "@/js/components/_contactForm";

/* Register components */
Alpine.data('contactForm', contactForm);

Alpine.start();

/* Components
//------------------*/

import '@js/components/index'

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
	import.meta.hot.accept(() => {
		console.log("HMR");
	});
}



